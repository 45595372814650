@font-face {
    font-family: bankGothic;
    src: url(images/BankGothic\ Md\ BT.ttf);
}

.App {
    background-color: #000;
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: bankGothic;
}

.container {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.mintRow {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.rowItem {
    width: 50%;
    padding: 64px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blocktonesLogo {
    margin-top: 64px;
    height: 26px;
    pointer-events: none;
}
.spinningLogo {
    height: 512px;
}

.button {
    background-color: #000;
    border: 1px #fff solid;
    color: white;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 32px;
    cursor: pointer;
    font-family: bankGothic;
}

.connectWalletBtn {
    position: absolute;
    right: 10px;
}

@media (max-width: 1000px) {
    .mintRow {
        flex-direction: column;
    }
    .rowItem {
        padding: 0px;
        width: 100%;
    }
    .container {
        width: 100%;
        position: absolute;
    }
    body {
        overflow: hidden;
        height: 100%;
        padding: 0px;
        margin: 0px;
    }
    .player-wrapper > div {
        width: unset !important;
        height: unset !important;
    }
    .spinningLogo {
        width: 256px;
        height: 256px;
    }
}
